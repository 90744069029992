import { jsx as _jsx } from "react/jsx-runtime";
import InitPage from "@/components/InitPage";
import { routes } from "@/routes";
import { Suspense } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.scss";
const router = createBrowserRouter([
    {
        // errorElement: <ErrorBlock status="empty" />,
        children: routes.map(({ url, component: RenderComponent, title }) => ({
            path: url,
            element: (_jsx(InitPage, { title: title, children: _jsx(RenderComponent, {}) })),
        })),
    },
], { basename: PREURL });
const App = () => {
    return (_jsx(Suspense, { fallback: null, children: _jsx(RouterProvider, { router: router }) }));
};
export default App;
