import { lazy } from "react";
export const commonRoutes = [
    {
        title: "Notion AI",
        url: "/",
        component: lazy(() => import("../pages/portal/index")),
    },
    {
        title: "readwise",
        url: "/readwise",
        component: lazy(() => import("../pages/readwise/index")),
    },
];
export const routes = [...commonRoutes];
